import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Offer } from '@memberspot/shared/model/offer';

export interface OffersState
  extends EntityState<Offer, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'offers' })
export class OffersStore extends EntityStore<OffersState> {
  constructor() {
    super();
  }
}
